import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { Concierge } from '@components/ConciergeBlock';

import { selectProfile } from '@features/profile/profile';

import { Services, StoreBag, UserHeadset } from '@images/icons';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { AllServices } from './AllServices';
import { BroadbandTile } from './Broadband';
import { EnergyTile } from './Energy';
import { Intro } from './Intro';
import { Removals } from './Removals';

export const Dashboard = () => {
  const profile = useSelector(selectProfile, shallowEqual)?.value;

  const firstName = profile?.customer?.name?.firstName ?? '';
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';
  const postcode = profile?.properties?.[0]?.postcode ?? '';
  const status = profile?.properties?.[0]?.status ?? '';

  const title = useMemo(
    () => (firstName ? `Hi ${firstName}, welcome Home` : 'Welcome Home'),
    [firstName]
  );

  const statusDescription = useMemo(() => {
    switch (status) {
      case 'BUYER':
        return 'Buying';
      case 'SELLER':
        return 'Selling';
      case 'TENANCY':
        return 'Renting';
      default:
        return undefined;
    }
  }, [status]);

  const statusItems = useMemo(
    () => [
      {
        title: 'Concierge',
        children: (
          <UserHeadset className="h-[42px] fill-white transition-all duration-300 group-hover:fill-brand" />
        ),
        href: '#home-team',
      },
      {
        title: 'Services',
        children: (
          <Services className="-ml-1 h-[52px] fill-white transition-all duration-300 group-hover:fill-brand" />
        ),
        href: 'https://home.cc/services',
      },
      {
        title: 'Home Store',
        children: (
          <StoreBag className="h-[42px] fill-white transition-all duration-300 group-hover:fill-brand" />
        ),
        href: 'https://store.home.cc',
        target: '_blank',
      },
    ],
    []
  );

  return (
    <main className="pb-20">
      <Intro
        title={title}
        pillText={statusDescription}
        address={address}
        images={[]}
        postcode={postcode}
        statusItems={statusItems}
      />
      <Concierge />

      <section className="mx-auto flex w-full max-w-7xl flex-col items-center gap-16 px-8 pt-16 lg:px-10">
        {postcode && <BroadbandTile postcode={postcode} />}
        {postcode && <EnergyTile postcode={postcode} />}
        <Removals />
        <AllServices />
      </section>
    </main>
  );
};
