import { useEffect, useState } from 'react';

import { WhatsApp } from '@images/icons';

export const QuickTip = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const dismissed = localStorage.getItem('quickTipDismissed');
    if (!dismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem('quickTipDismissed', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="flex w-full max-w-md flex-col items-start gap-4 rounded-2xl bg-white p-5 pt-4 shadow-homeLarge">
      <div className="flex w-full items-center justify-between">
        <h5 className="text-2xl font-medium">Quick tip</h5>
        <button className="p-1 underline" onClick={handleDismiss}>
          Dismiss
        </button>
      </div>
      <a
        className="flex w-full items-center gap-3"
        target="_blank"
        href="https://wa.me/447535742914"
        rel="noreferrer"
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#27D045]">
          <WhatsApp className="ml-0.5 mt-0.5 h-6 fill-white" />
        </div>
        <p className=" text-lg">Add us on WhatsApp 🙌</p>
      </a>
    </div>
  );
};
