import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Spinner } from '@components/Spinner';

import { useEnergyComparison } from '@hooks/useEnergyComparison';

import { Bulb, ChevronRight } from '@images/icons';

import { Deal } from './Deal';
import { RadioCard } from './RadioCard';

interface EnergyTileProps {
  postcode: string;
}

export const EnergyTile: React.FC<EnergyTileProps> = ({ postcode }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [usagePreset, setUsagePreset] = useState<'low' | 'medium' | 'high'>('medium');
  const [energyGroupType, setEnergyGroupType] = useState<
    'dualFuel' | 'electricityOnly' | 'gasOnly'
  >('dualFuel');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data, error, loading } = useEnergyComparison(postcode, 0, 250, {
    groupType: energyGroupType,
    usagePreset,
  });

  const updateUsagePreset = (preset: 'low' | 'medium' | 'high') => {
    setUsagePreset(preset);
    setShowDropdown(false);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  }, []);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown, handleClickOutside]);

  if (error || !data) return null;

  const {
    recommendations: { cheapestProduct, greenestProduct },
    totalCount,
  } = data;
  const hasProducts = data.totalCount > 0 && !!cheapestProduct && !!greenestProduct;

  const subtitle = !!totalCount
    ? `We've scanned ${totalCount} energy tariffs and found the best deals for you at`
    : "We've found the best energy deals for you at";

  return (
    <div className="flex w-full flex-col justify-center gap-8 rounded-3xl bg-white pb-12 pt-10 shadow-homeLarge">
      <h2 className="flex flex-col items-center justify-center gap-4 px-8 text-center text-3xl font-medium md:flex-row">
        <Bulb className="h-14 fill-primary" />
        <span>Secure the right Energy deal now</span>
      </h2>

      <h3 className="px-8 text-center text-2xl font-medium">
        {subtitle}
        &nbsp;
        <span className="underline">{postcode}</span>.
      </h3>
      <div className="flex justify-center gap-4">
        <RadioCard
          id="dualFuel"
          activeOption={energyGroupType}
          onChange={() => setEnergyGroupType('dualFuel')}
          label="Dual Fuel"
        />
        <RadioCard
          id="electricityOnly"
          activeOption={energyGroupType}
          onChange={() => setEnergyGroupType('electricityOnly')}
          label="Electricity Only"
        />
      </div>

      {!!loading ? (
        <div className="flex w-full justify-center py-8">
          <Spinner colour="black" />
        </div>
      ) : hasProducts ? (
        <div className="grid grid-cols-1 gap-12 px-8 py-5 md:gap-8 lg:grid-cols-2">
          {cheapestProduct._id === greenestProduct._id ? (
            <div className="col-span-2 flex flex-col items-center gap-2">
              <h4 className="text-center text-2xl font-medium">Your cheapest and greenest deal:</h4>
              <Deal {...cheapestProduct} />
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center gap-2">
                <h4 className="text-center text-2xl font-medium">Your cheapest deal:</h4>
                <Deal {...cheapestProduct} />
              </div>
              <div className="flex flex-col items-center gap-2">
                <h4 className="text-center text-2xl font-medium">Your greenest deal:</h4>
                <Deal {...greenestProduct} />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex w-full justify-center py-8">
          <p className="text-center text-2xl">We couldn't find any deals</p>
        </div>
      )}

      <div className="flex flex-col items-center justify-center gap-8 px-8">
        <p className="text-center text-lg">
          Prices based on Ofgems average energy use for a{' '}
          <button className="relative inline-block">
            <span
              className="flex items-center gap-0.5 font-semibold capitalize"
              onClick={() => setShowDropdown(true)}
            >
              {usagePreset} <ChevronRight className="h-3 w-3 rotate-90 fill-primary" />
            </span>
            {showDropdown && (
              <div
                ref={dropdownRef}
                className="absolute left-1/2 top-full mt-2 -translate-x-1/2 rounded-lg bg-white py-2 shadow-homeLarge"
              >
                <div className="flex flex-col gap-1">
                  <span
                    role="button"
                    tabIndex={0}
                    className="px-8 py-1"
                    onClick={() => updateUsagePreset('low')}
                  >
                    Low
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    className="px-8 py-1"
                    onClick={() => updateUsagePreset('medium')}
                  >
                    Medium
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    className="px-8 py-1"
                    onClick={() => updateUsagePreset('high')}
                  >
                    High
                  </span>
                </div>
              </div>
            )}
          </button>{' '}
          household.
        </p>
        <a
          href="/services/energy"
          rel="noreferrer"
          className="mt-auto flex gap-3 rounded-2xl border-2 border-black bg-black px-10 py-2 text-center text-lg font-medium text-white transition-all hover:border-gray-700 hover:bg-gray-700 md:px-12 md:py-4"
        >
          Confirm details and switch
        </a>
      </div>
    </div>
  );
};
