import { lazy } from 'react';

import { ForgotPasswordConfirm } from '@pages/forgotPasswordConfirm';
import { ServicesBroadbandPage } from '@pages/services/Broadband';
import { ServicesEnergyPage } from '@pages/services/Energy';

import { Dashboard } from './pages/dashboard';
import { SignIn } from './pages/signin';

// Lazy-loaded Pages
const Onboarding = lazy(() => import('./pages/onboarding'));
const OnboardingConfirmation = lazy(() => import('./pages/onboardingConfirmation'));
const Terms = lazy(() => import('./pages/terms'));
const ForgotPasswordUpdate = lazy(() => import('./pages/forgotPasswordUpdate'));
const ForgotPasswordMaybe = lazy(() => import('./pages/forgotPasswordMaybe'));
const Privacy = lazy(() => import('./pages/privacy'));

type RouteConfiguration = {
  path: string;
  title: string;
  component: any;
  private?: boolean;
  public?: boolean;
};

export const routeConfig: RouteConfiguration[] = [
  {
    path: '/terms',
    title: 'Terms & Conditions',
    component: Terms,
    private: true,
    public: true,
  },
  {
    path: '/privacy',
    title: 'Privacy Policy',
    component: Privacy,
    private: true,
    public: true,
  },
  {
    path: '/signin',
    title: 'Sign in',
    component: SignIn,
    public: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    component: ForgotPasswordMaybe,
    public: true,
  },
  {
    path: '/confirm-forgot-password',
    title: 'Confirm Forgot Password',
    component: ForgotPasswordConfirm,
    public: true,
  },
  {
    path: '/update-password',
    title: 'Update Password',
    component: ForgotPasswordUpdate,
    public: true,
  },
  {
    path: '/invitations',
    title: 'Welcome to Home',
    component: Onboarding,
    public: true,
  },
  {
    path: '/confirmation',
    title: 'Welcome to Home',
    component: OnboardingConfirmation,
    public: true,
  },

  {
    path: '/services/energy',
    title: 'Energy',
    component: ServicesEnergyPage,
    private: true,
  },
  {
    path: '/services/broadband',
    title: 'Broadband',
    component: ServicesBroadbandPage,
    private: true,
  },
  {
    path: '/',
    title: 'Home',
    component: Dashboard,
    private: true,
  },
];

type RedirectConfiguration = {
  from: string;
  to: string;
};

export const routeRedirects: RedirectConfiguration[] = [
  {
    from: '/wizard',
    to: '/wizard/find-a-property',
  },
];
